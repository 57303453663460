<template>
  <div>
    <SelaFetchError
      v-if="ads.error"
      :message="t('fetch_failed', { data: t('announcements') })"
    />

    <div v-else-if="ads.items?.length" class="max-w-screen-xl">
      <SelaSwiper id="secondary-swiper" swiper-pagination :items="ads.items">
        <template #slide="{ item }">
          <HomeAdSlide
            :key="item.id"
            :ad="item"
            :width="240"
            :height="isMobileOrTablet ? 60 : 40"
          />
        </template>
      </SelaSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { Ad } from '~~/composables/useMenuModel'
const { isMobileOrTablet } = useDevice()
defineProps<{
  ads: { error?: FetchError<any> | null; items: Ad[] | null }
}>()

const { t } = useI18n()
</script>

<style>
#secondary-swiper > .swiper > .swiper-pagination.main-pagination {
  @apply top-auto bottom-4;
}
</style>
